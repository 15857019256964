import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParameterCodec, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}

@Injectable()
export class RestService {

  constructor(private _cookie: CookieService, private _router: Router, private _httpClient: HttpClient) {
  }

  restClient({
               method,
               url,
               model,
               opt = <any>{responseType: 'json'}
             }): Observable<any> {
    const headers = new HttpHeaders(opt.headers ?
      {
        ...opt.headers,
        "X-CSRF-Token": this._cookie.get("X-CSRF-Token"),
      }
      :
      {
        "withCredentials": 'true',
        "X-CSRF-Token": this._cookie.get("X-CSRF-Token"),
      });
    let options = <any>{
      headers: headers,
      responseType: opt.responseType || 'json'
    };
    let obs;
    if (method === 'get') {
      options.params = new HttpParams({fromObject: model || {}});
      if (opt.customEncode) {
        options.params = new HttpParams({encoder: new CustomURLEncoder(), fromObject: model || {}});
      }
      obs = this._httpClient.get(url, options)
    } else if (method === 'post') {
      obs = this._httpClient.post(url, model, options)
    } else if (method === 'put') {
      obs = this._httpClient.put(url, model, options)
    } else if (method === 'delete') {
      obs = this._httpClient.delete(url, options)
    } else if (method === 'patch') {
      obs = this._httpClient.patch(url, model, options)
    }
    return obs
  }
}

