import {Action} from '@ngrx/store';

export enum ActionTypes {
  UpdStore = '[Add/Update store data] add/update',
}

export interface ICachePayload {
  key: string
  data: any
  lifeTime: number
}

export class UpdStore implements Action {
  constructor(public payload: ICachePayload) {
  }

  readonly type = ActionTypes.UpdStore;
}

export type ActionsUnion = UpdStore;
