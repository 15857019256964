import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { AppComponent }   from './app.component';
import {AppRoutingModule} from './app.routing';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthGuard} from './auth/_guard/auth.guard';
import {ReqInterceptor} from './auth/api.interceptor';
import {RestService} from './auth/_services/rest.service';
import {CookieService} from 'ngx-cookie-service';
import {StoreModule} from '@ngrx/store';
import {reducers} from "./store/reducers/reducesrs";
import {ToastrModule} from '@adcombo/adc-ui';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
    imports:      [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        ToastrModule.forRoot(),
    ],
    declarations: [ AppComponent ],
    providers: [
        CookieService,
        RestService,
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ReqInterceptor,
            multi: true
        },
    ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }