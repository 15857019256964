import {ActionsUnion, ActionTypes} from "../actions/cacheStore.actions";

export interface ICacheData {
  data: any
  ts: number
  lifeTime: number
}

export interface ICacheState {
  [key: string]: ICacheData
}

export const initialState: ICacheState = {};

export function cacheReducer(state = initialState, action: ActionsUnion): ICacheState {
  switch (action.type) {
    case ActionTypes.UpdStore:
      return {
        ...state,
        [action.payload.key]: {
          data: action.payload.data,
          ts: Date.now(),
          lifeTime: action.payload.lifeTime
        }
      };
    default:
      return state;
  }
}
