import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {RestService} from '../_services/rest.service';
import {empty, Observable} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import apiUrls from '../../store/const/api.consts';
import {navItems} from '../../_nav';
import {permissions} from '../../_nav';
import {UpdateUser} from '../../store/actions/globalStore.actions';
import {IState} from '../../store/reducers/reducesrs';
import {Store} from '@ngrx/store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _rest: RestService,
    private store: Store<IState>
  ) {
  }

  state = null;
  navItems = navItems;
  permissions = permissions;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const customPermission = route.data?.permission;
    const componentName = route.component['name'];
    return this._rest.restClient({method: "get", url: apiUrls.userInfo, model: {}}).pipe(
      map(next => {
        next.permissions = {
          ...next.permissions,
          viewAllUserInfo: !!next.permissions.users_available && !!next.permissions.view_basic_info_users
        }
        this.store.dispatch(new UpdateUser(next));
        let menuItem = this.permissions.find(i => i.component == componentName),
          local_host = location.host != "adm2.aclol.me",
          redirect = menuItem ? menuItem.old : false,
          checkPermissions = () => {
            if (!menuItem.permission) {
              return true
            }
            if (Array.isArray(menuItem.permission)) {
              if (menuItem.anyPermission) {
                return menuItem.permission.reduce((result, item) => result || next.permissions[item], false)
              } else {
                return menuItem.permission.reduce((result, item) => result && next.permissions[item], true)
              }
            }
            return next.permissions[menuItem.permission]
          };
        if (redirect) {
          window.location.href = local_host ? ('//' + location.hostname + menuItem.url) : ('//admin.aclol.me/' + menuItem.url);
          return false
        } else {
          let perm = customPermission?.length ? customPermission.every(permission => next.permissions[permission])
              : menuItem ? menuItem && checkPermissions() : true;
          // финальная проверка если не будет пермишена а юзер инфо есть, то кидаем на старт + ошибка
          if (!perm) {
            this._router.navigate(['/start'], {queryParams: {err: 'Access denied.'}});
          }
          return perm
        }
      }),

    ).pipe(tap((activate: boolean) => {
      if(!activate){
        // если вдруг мы по каким то причинам не имеем пермишена но есть юзеринфо, то редиректим на базовую страницу
        this._router.navigate(['/start'])
      }
    }),catchError((err, caught) => {
        //странная херня почемуто не работает, но пока не удаляем, кто выяснит тот молодец
        this._router.navigate(['login/'])
        return empty()
      }));
  }
}
