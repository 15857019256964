import {Action} from '@ngrx/store';

export enum ActionTypes {
  UpdateUser = '[Global update user] userinfo',
  PlusTest = '[Global test]change'
}

export class UpdateUser implements Action {
  constructor(public payload: any) {
  }

  readonly type = ActionTypes.UpdateUser;
}

export class PlusTest implements Action {
  constructor(public payload: number) {
  }

  readonly type = ActionTypes.PlusTest;
}

export type ActionsUnion = UpdateUser | PlusTest ;
