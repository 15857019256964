import {Action} from '@ngrx/store';
import {ActionsUnion, ActionTypes} from "../actions/globalStore.actions";

export interface UnsafeAction extends Action {
  payload?: any;
}

export interface IGlobalState {
  userInfo: null | { [key: string]: any }
  userPS?: null | { [key: string]: any }
}

export const initialState: IGlobalState = {
  userInfo: null
};

export function globalReducer(state = initialState, action: ActionsUnion): IGlobalState {
  switch (action.type) {
    case ActionTypes.UpdateUser:
      return {
        ...state,
        userInfo: action.payload
      }
    default:
      return state;
  }
}
