import {ActionReducerMap} from "@ngrx/store";
import {globalReducer, IGlobalState} from "./globalStore.reducers";
import {cacheReducer, ICacheState} from "./cacheStore.reducers";

export interface IState {
  app: IGlobalState
  cache: ICacheState
}

export const reducers: ActionReducerMap<IState> = {
  app: globalReducer,
  cache: cacheReducer,
}
