import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    '../scss/style.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() {
  }
}
