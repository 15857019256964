export const navItems = [
  {
    name: 'Пользователи',
    icon: 'users',
    children: [
      {
        name: 'Пользователи',
        url: '/users/',
        icon: 'user',
        component: 'UsersComponent',
        permission: 'users',
      },
        {
        name: 'Пермишены',
        url: '/permissions/',
        icon: 'sliders-h',
        component: 'PermissionsComponent',
        permission: 'permissions',
      },
    ]
  },
  {
    name: 'Каталог',
    url: '/catalog/',
    icon: 'file-o',
    component: 'CatalogComponent',
    permission: 'catalog',
  },
  {
    name: 'Ноутбуки',
    url: '/laptop/',
    icon: 'desktop',
    component: 'LaptopComponent',
    permission: 'laptop',
  },
  // {
  //   name: 'Калькулятор',
  //   url: '/calculate/',
  //   icon: 'sliders-h',
  //   component: 'CalculateComponent',
  //   permission: 'calculate',
  // },
];

export const permissions = navItems.reduce((acc, item) => {
  if (item.children) {
    acc = [
      ...acc,
      item,
      ...item.children
    ]
  } else {
    acc.push(item);
  }

  return acc
}, []);