const apiUrls = {
    enums: '/api/users/event_get_enums/',
    userInfo: '/api/auth/event_get_current_user/',
    login: '/api/auth/event_login/',
    logout: '/api/auth/event_logout/',
    sign_up: '/api/auth/event_sign_up/',
    users_list: '/api/users/',
    user_detail: (id) => `/api/users/${id}/`,
    graphics_card_list: '/api/graphics_card/',
    graphics_card_detail: (id) => `/api/graphics_card/${id}/`,
    cpu_list: '/api/cpu/',
    cpu_detail: (id) => `/api/cpu/${id}/`,
    cpu_manufacturer_list: '/api/cpu/event_get_manufacturer/',
    display_list: '/api/display/',
    display_resolution_list: '/api/display/event_get_resolution/',
    display_detail: (id) => `/api/display/${id}/`,
    ram_list: '/api/ram/',
    ram_detail: (id) => `/api/ram/${id}/`,
    memory_hdd_list: '/api/memory_hdd/',
    memory_hdd_detail: (id) => `/api/memory_hdd/${id}/`,
    memory_ssd_list: '/api/memory_ssd/',
    memory_ssd_detail: (id) => `/api/memory_ssd/${id}/`,
    os_list: '/api/os/',
    os_detail: (id) => `/api/os/${id}/`,
    calculate: '/api/calculate/event_get_check/',
    laptop_list: '/api/laptop/',
    laptop_manufacturer_list: '/api/laptop/event_get_manufacturer/',
};
export default apiUrls;
